/* eslint-disable react/no-children-prop */
import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import ReactMarkdown from 'react-markdown';
import Icon from './Icon';

const PersonToContact = ({ authorId, title, email }) => {
  const { allAuthorsJson } = useStaticQuery(graphql`
    query PersonToContactQuery {
      allAuthorsJson {
        edges {
          node {
            id
            name
            image
            url
            role
            phone
          }
        }
      }
    }
  `);

  const authorEdge = allAuthorsJson.edges.find(
    ({ node }) => node.id === authorId
  );

  const author = authorEdge ? authorEdge.node : null;

  return (
    author && (
      <div className="container person-to-contact">
        <div className="row">
          <h3>{title}</h3>
        </div>
        <br />
        <div className="row pb-2 pt-2 person-to-contact-wrapper">
          <div className="col-12 col-md-6 col-lg-6">
            <div className="person-to-contact-image">
              <img src={author.image} alt={`${author.name}`} />
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-6 person-to-contact-details-wrapper">
            <div className="person-to-contact-announcement">
              <b>{author.name && <ReactMarkdown children={author.name} />}</b>
            </div>
            <div>{author.role && <ReactMarkdown children={author.role} />}</div>
            <div>
              {author.phone && (
                <>
                  <Icon
                    iconName="Phone"
                    style={{ fontWeight: 'bold', padding: '2px' }}
                  />
                  {'  '}
                  <a href={`tel:${author.phone.replace(/\s/g, '')}`}>
                    {author.phone}
                  </a>
                </>
              )}
            </div>
            <div>
              <Icon
                iconName="Mail"
                style={{ fontWeight: 'bold', padding: '2px' }}
              />
              {'  '}
              <a href={`mailto:${email}`}>{email}</a>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default PersonToContact;
