import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/SEO';
import Layout from '../components/Layout';
import PersonToContact from '../components/PersonToContact';
import InterviewFlow from '../components/InterviewFlow';
import JobPostingOverview from '../components/JobPostingOverview';

export default function Careers({ data, location }) {
  const { html, image, frontmatter } = data.career;
  const { title } = frontmatter;

  return (
    <Layout>
      <SEO title={title} image={image} url={location.href} />

      <div className="strip pt-6">
        <div className="container">
          <h1>{title}</h1>
        </div>
      </div>

      <div className="strip pb-6">
        <JobPostingOverview />
      </div>

      <div className="strip-grey pt-6 pb-6">
        <InterviewFlow />
      </div>

      <div className="strip pt-6">
        <div className="container">
          <div dangerouslySetInnerHTML={{ __html: html }} />
        </div>
      </div>

      <div className="strip">
        <div className="container pt-6 pb-6">
          <div className="col-12">
            <div className="row">
              <PersonToContact
                authorId="sascha-kuntze"
                title="Dein Ansprechpartner bei Fragen zu unseren Stellenausschreibungen"
                email="jobs@pragmatic-development.io"
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query {
    career: markdownRemark(
      fileAbsolutePath: { regex: "/content/careers.md/" }
    ) {
      html
      frontmatter {
        title
        image
      }
      excerpt
    }
  }
`;
